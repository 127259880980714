import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Build a chat or voice bot and win prizes!`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/?utm_source=medium&utm_medium=blog&utm_campaign=event&utm_content=hackathon"
      }}>{`Dashbot`}</a>{` invites you to participate in the first-ever hackathon that will focus on conversational interfaces including messaging bots and voice-interactive technology. You will learn best practices from top names in the industry and build engaging bots with the brightest in the community.`}</p>
    <p>{`Whether you’re interested in building a messaging bot on Teams or Facebook or a Skill on Alexa, form a team to build a bot and win cool prizes! Come as a team or find yours at the hackathon.`}</p>
    <p><strong parentName="p">{`Sign up`}</strong>{` `}<a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/hackathon-bot-or-not-tickets-36531809626"
      }}><strong parentName="a">{`here`}</strong></a>{` `}<strong parentName="p">{`to reserve your spot.`}</strong></p>
    <h2>{`Rules`}</h2>
    <ol>
      <li parentName="ol">{`Your bot must be on a messaging or voice interface.`}</li>
      <li parentName="ol">{`Each team is limited a minimum of 3 people and maximum of 5 people.`}</li>
      <li parentName="ol">{`You must integrate `}<a parentName="li" {...{
          "href": "https://www.dashbot.io/?utm_source=medium&utm_medium=blog&utm_campaign=event&utm_content=hackathon"
        }}>{`Dashbot analytics`}</a>{`.`}</li>
      <li parentName="ol">{`All code must be written during the hackathon.`}</li>
      <li parentName="ol">{`Your bot must accomplish a task or provide a solution. In your pitch on Demo Day, you must identify the problem you’re solving and how your bot solves that problem.`}</li>
    </ol>
    <h2>{`Sponsors`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2017/09/06173812/6b94e-1b_jjytj_in-bkz5mokufqg-300x64.jpeg",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      